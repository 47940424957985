import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import MultiSelection from "../../../controls/MultiSelect";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import CallFilter from "./SSCallFilter";
import Switch from "react-switch";
import { components } from "react-select";
import DataTable from "react-data-table-component";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import Horizontal from "../../../shared/Slider";
import HorizontalCustomLabels from "../../../shared/Slider";
import DataTableView from "../../../shared/DataTable";
import moment from "moment";
import $ from "jquery";
import MultiRangeSlider from "../../../shared/multiRangeSlider/MultiRangeSlider";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import { end } from "@popperjs/core";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

let headers = [
  { label: "File Name", key: "wav" },
  { label: "Agent ID", key: "agentid"},
  { label: "Form Name", key: "form_name"},
  { label: "Datetime", key: "datetime"},
];
let newSSHeaders = []

// const checkExport = (checked,value) => {
//   if(checked && headers?.some(i=>i?.addRemove!==value)){
    
    
//   }
//   else{
//     headers=headers?.filter(i=>i?.addRemove!==value)
//   }
//   console.log("4343434343343443",headers);

// }
var tagsArr = [];
var lastid = "";
var lastTagId = "";
// var tagsArr = [];
var tagsDetailsArr = [];
// var termDataArray = [];
let phrasesAppList = [
  {
    type: "agent",
    phrases: [],
  },
  {
    type: "client",
    phrases: [],
  },
  {
    type: "speaker",
    phrases: [],
  },
];
const SSCallSearch = () => {
  TabTitle("SmartSpeech-CallSearch :: NEQQO");
  var optionsAppList = [];
  var optionsFolderList = [];

  const navigate = useNavigate();

  const [lobData, setLobData] = useState([]);
  const [compaignData, setCompaignData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [locData, setLocData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedLobData, setSelectedLobData] = useState();
  const [selectedCampData, setSelectedCampData] = useState();
  const [selectedVendorData, setSelectedVendorData] = useState();
  const [selectedLocData, setSelectedLocData] = useState();
  const [maxFlag, setMaxFlag] = useState(false);
  const [termCreator, setTermCreator] = useState("Speaker");
  const [termsArr, setTermsArr] = useState([]);
  const [termWord, setTermWord] = useState("");
  const [endDuration, setEndDuration] = useState("");
  const [startDuration, setStartDuration] = useState("");
  const [endSlTime, setEndSlTime] = useState("");
  const [startSlTime, setStartSlTime] = useState("");

  const [genderOF, setGenderOF] = useState("all_gender");
  const [genderType, setGenderType] = useState("all");

  const [emotionOF, setEmotionOF] = useState("");
  const [emotionVal, setEmotionVal] = useState("");

  const [callSearchData, setCallSearchData] = useState([]);

  const [isLoading, setIsLoading] = React.useState(true);

  const [from_date, setFromDate] = useState(
    moment().clone().startOf("month").format("YYYY-MM-DD")
  );
  const [to_date, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const [callID, setCallId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [allBookMarksData, setAllBookMarksData] = useState([]);
  const [allBookTagsData, setAllTagssData] = useState([]);

  const [switchBtn, setSwitchBtn] = useState(false);
  const [tagsOrAndValue, setTagsOrAndValue] = useState();
  const [bookmarkBooleanValue, setBookmarkBooleanValue] = useState();

  const [tagsArrData, setTagsArrData] = useState({
    tags: [
      { operator: "OR", tag_details: [{ terms_text: "", terms_phrase: "" }] },
    ],
  });

  const [silenceTimeValue, setSilenceTime] = useState("");
  const [durationValue, setDuration] = useState("");

  const [agent_id, setAgentId] = useState("");
  const [call_id, setCallID] = useState("");

  const [termVal, setTerm] = useState();
  const [termText, setTermText] = useState();

  const [searchTermValue, setSearchTermValue] = useState([]);
  const [termDataArray, setTermDataArray] = useState([]);
  const [bookMarkValue, setBookMarkValue] = useState("");

  const [appListData, setAppListData] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [applicationListData, setApplicationListData] = useState([]);
  

  const [folderListData, setFolderListData] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState(false);

  const [appID, setAppID] = useState([]);
  const [appName, setAppName] = useState("");

  const [tagID, setApplicationId] = useState([]);
  const [tagName, setTagName] = useState("");
  const [folderID, setFolderID] = useState("");
  const [folderName, setFolderName] = useState("");

  const [clearApp, setClearApp] = useState(false);
  const [showBookMark, setShowBookMark] = useState("d-block");
  const [showKeyWord, setShowKeyword] = useState("d-block");

  const [c1Data, setC1Data] = useState([]);
  const [c2Data, setC2Data] = useState([]);
  const [c3Data, setC3Data] = useState([]);
  const [c4Data, setC4Data] = useState([]);

  const [searchLoader, setSearchLoader] = useState(false);

  const [defaultValueC1, setdefaultValueC1] = useState([]);
  const [defaultValueC2, setdefaultValueC2] = useState([]);
  const [defaultValueC3, setdefaultValueC3] = useState([]);
  const [defaultValueC4, setdefaultValueC4] = useState([]);

  const [hierarchyData, setHierarchyData] = useState([]);
  const [allAplications, setAllAplications] = useState([]);

  const [applicationValue, setApplicationValue] = useState([]);
  const [filteredApplication, setFilteredApplication] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const [filteredFolders, setFilteredTags] = useState([]);
  const [tagID2, setTagId2] = useState("");
  const [applicationId2, setApplicationId2] = useState("");
  const [appValue, setAppValue] = useState("");
  const [valueOfTags, setValueOfTags] = useState("");
  const [columns2, setColumns2] = useState([]);

  const [headersForExport, setHeadersForExport] = useState([]);
  const [secureHeadersForExport, setSecureHeadersForExport] = useState([]);
  const [exportStatus, setExportStatus] = useState([]);


  let getAllDataForExport = []

  const addRemoveTableValues = (e, column) => {
   
    getAllDataForExport=headersForExport

    const columnExists = newSSHeaders?.some(i => i.label === column);
    if(e === true && !columnExists) {
      
      
      newSSHeaders = [...(newSSHeaders || []), { label: column,key:column }];
      secureHeadersForExport.forEach((item, index) => {
        // Filter out the specific column from the entries and convert back to an object
        const filteredItem = Object.fromEntries(Object.entries(item).filter(([key]) => key === column));
        // Merge the filtered item with the existing item in secureHeadersForExport
        secureHeadersForExport[index] = { ...item, ...filteredItem };
      });
      const newHeaders = secureHeadersForExport.map(item => {
        return Object.fromEntries(Object.entries(item).filter(([key]) => key === column));
      });
      newHeaders?.map((i,index)=>{
        getAllDataForExport[index][Object.keys(i)[0]]=Object.values(i)[0]
      })
      
      
      console.log("newHeadersnewHeaders",newHeaders);
      console.log("filteredItemfilteredItem",getAllDataForExport);
      setExportStatus(newHeaders);
      csvReport = {
        data: getAllDataForExport,
        headers: newSSHeaders,
        filename: 'ss_call_search.csv'
    };

    console.log("csvReportcsvReport",csvReport);
      

      // setHeadersForExport(secureHeadersForExport.map(item => {
      //   // Filter out the column from the entries and convert back to an object
      //   return Object.fromEntries(Object.entries(item).filter(([key]) => key === column))
      // }))
    }
    else{
      newSSHeaders = newSSHeaders?.filter(i=>i?.label!==column)
      setHeadersForExport(headersForExport.map(item => {
        // Filter out the column from the entries and convert back to an object
        return Object.fromEntries(Object.entries(item).filter(([key]) => key !== column));
    }));
    csvReport = {
      data: headersForExport,
      headers: newSSHeaders,
      filename: 'ss_call_search.csv'
  };
    
    }

    
    console.log("headersForExport1223",newSSHeaders,headersForExport);
    
   
  };

  
 

  

  

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
    setMaxFlag(true)
    localStorage.setItem("phraseData","")
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  console.log("isDrawerOpen call search", isDrawerOpen);

  useEffect(() => {
    setIsLoading(false);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    // var url = `${base_url}callspeech/getmetadata`;
    // callApi(url, requestOptions, "meta-data");

    var url = `${base_url}callspeech/getallbookmark/${localStorage.getItem(
      "loginUserUniqueid"
    )}`;
    callApi(url, requestOptions, "all-bookmarks");

    getAllFolders();

    // var url = `${base_url}callspeech/application-list`;
    // callApi(url, requestOptions, 'app-list');
  }, []);

  // useEffect(()=>{
  //     termDataArray = []
  //     termsArr && termsArr?.map(item=>{
  //         let value = item
  //         termDataArray.push(value?.split(':')[1])
  //     })
  // },[termsArr])

  const onChangeSlienceTime = (min, max) => {
    console.log("onChangeSlienceTime", min, max);
    setEndSlTime(max.toString());
    setStartSlTime(min.toString());
  };

  const onChangeDuration = (min, max) => {
    console.log("onChangeDuration", min, max);

    setEndDuration(max.toString());
    setStartDuration(min.toString());
  };

  useEffect(() => {
    getCustomHierarchyData();
  }, []);

  const getCustomHierarchyData = async () => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("hierarchy data======", data);
        if (isNaN(data.status)) {
          // Logout();
          return;
        }
        var arr = [];
        data.data.map((i, j) => {
          arr[i.custom1_id] = i.custom1_name;
          arr[i.custom2_id] = i.custom2_name;
          arr[i.custom3_id] = i.custom3_name;
          arr[i.custom4_id] = i.custom4_name;
        });
        // arr['c2'] = 'Vendor';
        // arr['c3'] = 'LOB';
        // arr['c1'] = 'Campaign';
        // arr['c4'] = 'Location';
        console.log("arr -===========", arr);
        setHierarchyData(arr);
      })
      .catch((err) => {
        console.log(err);
      });

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    var url = `${base_url}callspeech/get-meta-campaign`;
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("data campaign",data)
        if(data?.data?.length > 0 && data?.data[0]?.campaign?.length > 0){
        setC1Data(data?.data[0]?.campaign);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedLobData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedLobData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainerForTag = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    console.log("tagValuetagValue234445", tagValue);
    if (tagValue && tagValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${tagValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedCampData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedCampData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainerForApplication = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    console.log("applicationValueapplicationValue", applicationValue);
    if (applicationValue && applicationValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${applicationValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer3 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedVendorData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedVendorData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer4 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedLocData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedLocData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const optionsC1 = Object.keys(lobData)
    .filter((key) => lobData[key] !== "NA" && lobData[key] !== null)
    .map((item) => ({
      value: lobData[item],
      label: lobData[item],
    }));
  {
    console.log("Options11111", optionsC1);
  }

  const optionsC2 = Object.keys(c1Data)
    .filter((key) => c1Data[key] !== "NA" && c1Data[key] !== null)
    .map((item) => ({
      value: c1Data[item],
      label: c1Data[item],
    }));

  const optionsC3 = Object.keys(vendorData)
    .filter((key) => vendorData[key] !== "NA" && vendorData[key] !== null)
    .map((item) => ({
      value: vendorData[item],
      label: vendorData[item],
    }));
  const optionsC4 = Object.keys(locData)
    .filter((key) => locData[key] !== "NA" && locData[key] !== null)
    .map((item) => ({
      value: locData[item],
      label: locData[item],
    }));

  const handleSearch = () => {
    console.log("allBookTagsData are========", allBookTagsData);
    console.log("loadwer are========", searchLoader);

    if (folderID == "") {
      ErrorNotify("Please Select Folder");
      return;
    }
    setSearchLoader(true);
    if(tagID2?.length>0){

    }
    // console.log('durationValue are========', durationValue);
    // return;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        // lob: selectedLobData,
        campaign: selectedCampData,
        // vendor: selectedVendorData,
        // location: selectedLocData,
        gender: genderOF,
        gender_type: genderType,
        emotion: emotionOF ? emotionOF : "",
        emotion_type: emotionVal ? emotionVal : "",
        from_date: from_date,
        to_date: to_date,
        // terms: tagsArr,
        // phrases: termWord,
        terms: termsArr.length ? termsArr : tagsArr,
        silent_time: endSlTime,
        start_silent_time: startSlTime,
        duration: endDuration,
        start_duration: startDuration,
        agent_id: agent_id.length > 0 ? [agent_id] : [],
        call_id: call_id,
        application: applicationId2?.length>0?applicationId2:[],
        operator: tagsOrAndValue ? "AND" : "OR",
        tag: tagID2.length > 0 ? tagID2 : [],
        folder_id: folderID,
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
      }),
    };
    var url = `${base_url}callspeech/${window.location.host?.split(".")[0] === "recruitment" ? "recruit-filter" : "filter"}`;
    callApi(url, requestOptions, "call-search");
  };

  const selectedLobDataFunc = (val) => {
    console.log("lobbVALUE", val);
    setSelectedLobData(val);
    console.log("lobbVALUE2", selectedLobData);
  };

  const selectedCampDataFunc = (val) => {
    console.log("lobbCamppain", val);
    setSelectedCampData(val);
    console.log("lobbcampai2", selectedCampData);
  };

  const selectedVendorDataFunc = (val) => {
    console.log("lobbVender", val);
    setSelectedVendorData(val);
    console.log("lobbVendor2", selectedVendorData);
  };

  const selectedLocDataFunc = (val) => {
    console.log("lobbLOC", val);
    setSelectedLocData(val);
    console.log("lobbLOC2", selectedLocData);
  };

  const getBookmarkTags = (val, accordian_id, id) => {
    let terms = allBookMarksData
      .filter((id) => id.bookmark_id === val)
      .map((bm) => bm.terms);
    setAllTagssData(terms[0]);
    setTagsArrFunc(terms[0]);
    $(`#${accordian_id}`).toggle("fast", "linear");
    $(`#${id}`).css("backgroundColor", "#0686D0");
    $(`#${id}`).css("color", "#fff");

    if (lastid !== "") {
      $(`#${lastid}`).css("backgroundColor", "");
      $(`#${lastid}`).css("color", "#000");
    }

    if (lastid !== "" && lastid !== id) {
      $(`#${accordian_id}`).toggle("fast", "linear");
    }

    if (lastid === id) {
      $(`#${id}`).css("backgroundColor", "");
      $(`#${id}`).css("color", "#000");
      lastid = "";
    } else {
      lastid = id;
    }
  };

  const removeTermsValue = (tt) => {
    const termKey = tt?.split(`:`);
    // termDataArray?.map((item,ind)=>{
    //     if(item === termKey[1]){
    //         termDataArray.splice(ind, 1)
    //     }
    // })

    setTermDataArray(
      termDataArray?.length > 0
        ? termDataArray.filter((item) => item !== termKey[1])
        : []
    );
    setTermsArr(
      termsArr?.length > 0
        ? termsArr.filter((item) => item !== tt)
        : []
    );

    phrasesAppList?.map(item=>{
      if(item?.type == termKey[0]?.toLowerCase()){
        item.phrases = item?.phrases?.filter(e => e !== termKey[1])
      }
    })
    // var index;
    // allBookTagsData.map((i,j) => {
    //     console.log(i)
    // })
    // console.log('index of======', index);
    // var abcde = allBookTagsData.filter(tt1 => tt1.terms_text !== tt);
    // allBookTagsData.push(abcd)
    // setAllTagssData(prevState => [
    //     ...prevState,
    //     abcd
    // ]);
    // console.log('tagsarr1=============', abcde);
  };

  const setTagsArrFunc = (terms, id) => {
    tagsArr = [];
    if (!typeof terms === String)
      terms.map((i, j) => {
        tagsArr.push(i.terms_name);
      });
    // tagsArr.map((i, j) => {
    //     i.terms.push({
    //         terms_name : terms_name
    //     })
    // })
  };

  const setSilenceTimeValue = (val) => {
    setSilenceTime(val);
  };

  const setDurationValue = (val) => {
    setDuration(val);
  };

  const setSilenceTimeValueFunc = (min, max) => {
    setSilenceTime(min);
  };
  console.log("termsArr", termsArr);

  const setSearchTerm = () => {
    // var term_name = termVal + ':' + termText;
    setSearchTermValue((prevState) => [...prevState, termVal + ":" + termText]);
  };

  const setTermSearchCriteria = () => {
    $("#ats_accordion_05").toggle("slow", "linear");
    phrasesAppList?.map((app) => {
      if (app?.type== termCreator?.toLowerCase()) {
        app?.phrases?.push(termWord);
      }
    });
    setAllTagssData((prevState) => [
      ...prevState,
      {
        terms_id: "",
        terms_name: termCreator + ":" + termWord,
        terms_createdBy: localStorage.getItem("loginUserUniqueid"),
      },
    ]);

    setTermsArr((prevState) => [...prevState, termCreator + ":" + termWord]);
    setTermDataArray((terms) => [...terms, termWord]);
    setTermWord("");
  };

  const handleAddBookmark = () => {
    if (bookMarkValue === "") {
      ErrorNotify("Please enter bookmark name");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        bookmark_name: bookMarkValue,
        created_by: localStorage.getItem("loginUserUniqueid"),
        operator: bookmarkBooleanValue ? "AND" : "OR",
        terms: searchTermValue,
      }),
    };
    var url = `${base_url}callspeech/addbookmark`;
    callApi(url, requestOptions, "add-bookmark-value");
  };

  const addTermValue = () => {
    setSearchTermValue([]);
    allBookTagsData.map((i, j) => {
      // var pair = i.terms_text + ':' + i.terms_phrase;
      setSearchTermValue((prevState) => [...prevState, i.terms_name]);
    });
  };

  const onMaxClick = (e) => {
    console.log("onMaxClick", e);
  };

  const handleAppChange = (e) => {
    setClearApp(true);
    setAppID(e.value);
    setAppName(e.label);
    // getFolders(e.value);

    // setFolderListData([]);
    // setFolderID('');
    // setFolderName('');
  };

  // getting all folders on call search
  const getAllFolders = () => {
    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "all-folders-list");
  };

  // getting applications based on folder id
  const getFoldersApplications = (folder_id) => {
    setAppName("");
    setAppID([]);
    var url = `${base_url}tags/app-folder/${
      folder_id?.length > 0 && folder_id
    }`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-wise-app-list");
  };

  // getting applications based on folder id
  const getFoldersTags = (folder_id) => {
    setTagName("");
    setApplicationId([]);
    var url = `${base_url}tags/tag-folder/${
      folder_id?.length > 0 && folder_id
    }`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-wise-tag-list");
  };

  

  const handleFolderChange = (e) => {
    setFolderID(e.value);
    setFolderName(e.label);
    getFoldersApplications(e.value);
  };

  // getting folder based on application id
  const getFolders = (app_id) => {
    var url = `${base_url}folder/all-application-folder-list/${app_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-list");
  };

  // remove bookmarks here
  const removeBookmarks = (id) => {
    var url = `${base_url}callspeech/delbookmark`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        bookmark_id: id,
      }),
    };
    callApi(url, requestOptions, "remove-bookmarks");
  };
  console.log("termDataArray", termDataArray);

  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "all-folders-list":
            console.log("all-folders-list------", data);
            if (data.status === 200) {
              setFolderListData(data.data);
            }
            break;

          case "folder-wise-app-list":
            console.log("folder-wise-app-list---------", data);
            if (data.status === 200) {
              
              let appListArr = [];
              data?.data?.map((item) => {
                const values = {
                  label: item.application_name,
                  value: item.application_id,
                };
                appListArr.push(values);
                console.log("HGFDKGFDF", appListArr);
              });
              setApplicationListData(appListArr);
            }
            break;

          case "folder-wise-tag-list":
            console.log("folder-wise-tag-list---------", data);
            if (data.status === 200) {
              let tagListArr = [];
              data?.data?.map((item) => {
                const values = {
                  label: item.tag_name,
                  value: item.tag_id,
                };
                tagListArr.push(values);
                console.log("HGFDKGFDF", tagListArr);
              });
              setTagListData(tagListArr);
            }
            break;

          case "remove-bookmarks":
            console.log("remove-bookmarks-------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setAllTagssData([]);
              return;
            }
            break;

          case "folder-list":
            console.log("folder-list--------", data);
            if (data.status) {
              setFolderListData(data.data);
            }
            break;

          case "app-list":
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              setAppListData(
                data.data.application
                  .filter((i, j) => i.application_type === "general")
                  .map((app) => app)
              );
              return;
            }
            break;

          // case "meta-data":
          //   // console.log('meta data============', JSON.parse(data.data));
          //   if (isNaN(data.status)) {
          //     // swal(data.status);
          //     Logout();
          //     return;
          //   }
          //   if (data.status === 200) {
          //     setLobData(JSON.parse(data.data).lob);
          //     {
          //       console.log("metaLobDAta", lobData);
          //     }
          //     setCompaignData(JSON.parse(data.data).campaign);
          //     {
          //       console.log("metaLobDAtacomp", compaignData);
          //     }
          //     setVendorData(JSON.parse(data.data).vendor);
          //     {
          //       console.log("metaLobDAtavendor", vendorData);
          //     }
          //     setLocData(JSON.parse(data.data).loc);
          //     {
          //       console.log("metaLobDAtaloc", locData);
          //     }
          //   }
          //   // swal(data)
          //   break;
          case "call-search":
            console.log("call search data", data.data);

            if (data?.status == 200 || data?.status == 201) {

              if (data?.data?.length > 0) {
                    //let getHeader = Object.keys(data?.data[0]?.meta_data).concat(Object.keys(data?.data[0]))?.filter(i => i !== "meta_data" && i !== "_id")
                    let getHeader = Object.keys(data?.data[0]).concat(data?.folder_meta[0]?.metadata)?.filter(i=>i!=="meta_data")
                    if (getHeader.includes("call_id")) {
                      getHeader = getHeader.filter(i => i !== "call_id");
                      getHeader.unshift("call_id");
                    }
                    newSSHeaders = getHeader?.map((item) => ({
                        label: item,
                        key: item
                    }));
                    let newSSHeaders2 = getHeader?.map((item) => ({
                      Header: item?.replace(/_/g, " ").replace(/^\w/, char => char.toUpperCase()),


                      accessor: item
                  }));

                    setColumns2(newSSHeaders2)

                    
                }
                let newData = [];
                data?.data?.map(item => {
                    newData.push({
                        ...item,

                        ...item.meta_data,
                        folder_id: item.folder_id,
                        applications:item?.applications?.map(i=>i?.application_name?i?.application_name:i),
                        tag:item?.tag?.map(i=>i?.tag_name?i?.tag_name:i)

                    });
                });

                setHeadersForExport(newData)
                setSecureHeadersForExport(newData)

                console.log("newDatanewData", newData,newSSHeaders);

                csvReport = {
                    data: newData,
                    headers: newSSHeaders,
                    filename: 'ss_call_search.csv'
                };
                xlsReport = {
                  data: newData,
                  headers: newSSHeaders,
                  filename: 'ss_call_search.xlsx'
              };
              console.log("xlsReportxlsReport",xlsReport);
                

              setCallSearchData(data.data);
              setIsLoading(false);
              setSearchLoader(false);
              
              setIsDrawerOpen(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "all-bookmarks":
            console.log("all-bookmarks-----------", data);
            if (data.status === 200) {
              setAllBookMarksData(data.data.bookmarks);
            }
            break;
          case "add-bookmark-value":
            console.log("added bookmark data ------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setTimeout(() => {
                window.location.reload();
              }, 5600);
              return;
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        setIsLoading(false);
        setSearchLoader(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  // var dynamicHeadersArr = [];
  // var dataArr = {};

  // var arr = [
  //     { "cat_name": "Test Category 1", "phrase_count_percentage": "31%" },
  //     { "cat_name": "Test Category 2", "phrase_count_percentage": "69%" },
  //     { "cat_name": "Test Category 3", "phrase_count_percentage": "0%" }
  // ];

  // arr.map((i, j) => {
  //     dynamicHeadersArr.push(
  //         { Header: i.cat_name, accessor: i.cat_name.replace(/ /g, "_") }
  //     )
  //     dataArr[i.cat_name.replace(/ /g, "_")] = <p>{i.phrase_count_percentage}</p>;
  // });

  const columns = React.useMemo(
    () =>
      // dynamicHeadersArr,
      [
        {
          Header: "Call ID",
          accessor: "call_id", // accessor is the "key" in the data
        },
        {
          Header: "Agent ID",
          accessor: "agent_id",
        },
        {
          Header: "Form Name",
          accessor: "form_name",
        },

        {
          Header: "Time",
          accessor: "time", // accessor is the "key" in the data
        },
        {
          Header: "Duration",
          accessor: "duration",
        },
        {
          Header: "Silence Time",
          accessor: "silence_time",
        },
        {
          Header: "Overtalk",
          accessor: "overtalk",
        },

        {
          Header: "Diarization",
          accessor: "diarization", // accessor is the "key" in the data
        },
        {
          Header: "Agent Clarity",
          accessor: "agent_clarity",
        },
        {
          Header: "Client Clarity",
          accessor: "client_clarity",
        },
        {
          Header: "Agent Gender",
          accessor: "agent_gender",
        },
        {
          Header: "Client Gender",
          accessor: "client_gender",
        },
        {
          Header: "Overall Emotion",
          accessor: "overall_emotion",
        },
        {
          Header: "Agent Emotion",
          accessor: "agent_emotion",
        },

        {
          Header: "Client Emotion",
          accessor: "client_emotion",
        },

        {
          Header: "Transcript ID",
          accessor: "transcript_id",
        },

        {
          Header: "Request ID",
          accessor: "request_id",
        },

        {
          Header: "Score",
          accessor: "score",
        },
      ],
    []
  );

  const data = React.useMemo(() =>
    callSearchData
      ?.filter((item) => {
        return searchValue === ""
          ? item && item
          : (item?.agent_id &&
              item?.agent_id?.toLowerCase().includes(searchValue)) ||
              (item?.call_id &&
                item?.call_id?.toLowerCase().includes(searchValue)) ||
              (item?.agent_emotion &&
                item?.agent_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.agent_gender &&
                item?.agent_gender?.toLowerCase().includes(searchValue)) ||
              (item?.call_view_status &&
                item?.call_view_status?.toLowerCase().includes(searchValue)) ||
              (item?.campaign &&
                item?.campaign?.toLowerCase().includes(searchValue)) ||
              (item?.client_emotion &&
                item?.client_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.client_gender &&
                item?.client_gender?.toLowerCase().includes(searchValue)) ||
              (item?.datetime &&
                item?.datetime?.toLowerCase().includes(searchValue)) ||
              (item?.duration &&
                item?.duration?.toLowerCase().includes(searchValue)) ||
              (item?.folder_id &&
                item?.folder_id?.toLowerCase().includes(searchValue)) ||
              (item?.lob && item?.lob?.toLowerCase().includes(searchValue)) ||
              (item?.location &&
                item?.location?.toLowerCase().includes(searchValue)) ||
              (item?.overall_emotion &&
                item?.overall_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.overtalk &&
                item?.overtalk?.toLowerCase().includes(searchValue)) ||
              (item?.silence_time &&
                item?.silence_time?.toLowerCase().includes(searchValue)) ||
              (item?.tran_id &&
                item?.tran_id?.toLowerCase().includes(searchValue)) ||
              (item?.transcription_status &&
                item?.transcription_status
                  ?.toLowerCase()
                  .includes(searchValue)) ||
              (item?.vendor &&
                item?.vendor?.toLowerCase().includes(searchValue)) ||
              (item?.wav && item?.wav?.toLowerCase().includes(searchValue));
        // console.log("ITEN",item);
      })
      .map((i, j) => {
        const dynamicObject = {};
      
        // Iterate through the top-level properties
        for (const key in i) {

          if (i.hasOwnProperty(key) && key !== 'meta_data') {
            dynamicObject[key] = (
              <p className="td-link">
                {key === "call_id" ? (
                  <Link
                    to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i.call_id}`}
                    target="_blank"
                    onClick={() => {
                     
                      // Set new call data
                      localStorage.setItem(`PhraseData_${i.call_id}`, JSON.stringify(phrasesAppList));
                    }}
                  >
                    {i.call_id}
                  </Link>
                ) : Array.isArray(i[key]) ? (
                  
                  i[key].map(subItem => <span key={subItem}>{subItem+","}</span>
                )
                ) : 
                (
                  i[key] ? i[key] : "NA"
                )}
              </p>
            );
          }
        }
      
        // Iterate through the meta_data properties
        if (i.meta_data) {
          for (const metaKey in i.meta_data) {
            if (i.meta_data.hasOwnProperty(metaKey)) {
              dynamicObject[metaKey] = (
                <p className="td-link">
                  {i.meta_data[metaKey] ? i.meta_data[metaKey] : "NA"}
                </p>
              );
            }
          }
        }
      console.log("TTTTTTTTTTTTTTT",dynamicObject);
        return dynamicObject;
      })
      
  );

  // const data = [];

  const handleApplicationChange = (e, event) => {
    csvReport = {
      data: [],
    };
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setApplicationValue(applicationListData);
      console.log("11111111111", applicationListData);
      setFilteredApplication && setFilteredApplication(applicationListData);
      let allTagValue = [];
      applicationListData &&
      applicationListData?.map((item) => {
          if (item?.value !== "All") {
            allTagValue.push(item?.value);
          }
        });
      setApplicationId2(allTagValue);
      console.log("allTagValue12222", allTagValue);
      // // downloadTagReport(allTagValue, to_date)
    }
    else if (event?.option?.value == "All" && event?.action == "deselect-option") {
      console.log("2222222222");
      setFilteredApplication && setFilteredApplication([]);
      setApplicationValue([]);
      setApplicationId2([]);
      // // // downloadTagReport([], to_date)
    } else {
      if (e.some((i) => i.value == "All")) {
        console.log("3333333333");
        let filterData = e.filter((item) => item?.value !== "All");

        setApplicationValue(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setApplicationId2(filterDataValue);
        //// // downloadTagReport(filterDataValue, to_date)
      }
      else {
        // if (tagListData?.length - 1 == e?.length) {
        //   console.log("444444444");
        //     setApplicationValue(tagListData);

        //     setApplicationId(["All"]);
        // }
        // else {
          console.log("55555555555");
          if (e?.length > 10) {
            return ErrorNotify("Can not select tags more than 10 at a time")
          }
          else {
            let tagData = [];

            e?.map((item) => {
              tagData.push(item);
            });
            setApplicationValue(tagData);
            setFilteredApplication && setFilteredApplication(tagData);
            let tagValue = [];
            e?.map((item) => {
              tagValue.push(item.value);
            });
            setApplicationId2(tagValue);
            // // // downloadTagReport(tagValue,to_date)

          }
        // }
      }
    }
    setValueOfTags("")
    setTagValue([])
    setTagId2([])
    console.log("eeeeeeeeeeeeee", tagID);
    // // // downloadTagReport(e?.map(i=>i?.value),to_date)

  };

  const handleTagChange = (e, event) => {
    csvReport = {
      data: [],
    };
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setTagValue(tagListData);
      console.log("11111111111", tagListData);
      setFilteredTags && setFilteredTags(tagListData);
      let allTagValue = [];
      tagListData &&
        tagListData?.map((item) => {
          if (item?.value !== "All") {
            allTagValue.push(item?.value);
          }
        });
      setTagId2(allTagValue);
      console.log("allTagValue12222", allTagValue);
      // downloadTagReport(allTagValue, to_date)
    }
    else if (event?.option?.value == "All" && event?.action == "deselect-option") {
      console.log("2222222222");
      setFilteredTags && setFilteredTags([]);
      setTagValue([]);
      setTagId2([]);
      // // downloadTagReport([], to_date)
    } else {
      if (e.some((i) => i.value == "All")) {
        console.log("3333333333");
        let filterData = e.filter((item) => item?.value !== "All");

        setTagValue(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setTagId2(filterDataValue);
        // downloadTagReport(filterDataValue, to_date)
      }
      else {
        
          console.log("55555555555");
          if (e?.length > 10) {
            return ErrorNotify("Can not select tags more than 10 at a time")
          }
          else {
            let tagData = [];
            e?.map((item) => {
              tagData.push(item);
            });
            setTagValue(tagData);
            setFilteredTags && setFilteredTags(tagData);
            let tagValue = [];
            e?.map((item) => {
              tagValue.push(item.value);
            });
            setTagId2(tagValue);
            // // downloadTagReport(tagValue,to_date)

          }
        
      }
    }
    setAppValue("")
    setApplicationValue([])
    setApplicationId2([])
    
   
    // console.log("eeeeeeeeeeeeee", tagID2);
    // // downloadTagReport(e?.map(i=>i?.value),to_date)

  };

  return (
    <div className="page-wrapper" style={{ backgroundImage: "none" }}>
      {/* <Header />
            <Sidebar /> */}
      {/* <CallFilter /> */}
      {/* main section */}
      <main>
        <ToastContainer />
        <div className="main-contents">
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div
                className="alert alert-info text-center"
                id="success_msg"
              ></div>
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg"></button>
            </div>
          </div>
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                  <span>Call Search</span>
                </h3>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">Call</a>
              </li>
              <li className="breadcrumb-item active">
                <span>Call Search</span>
              </li>
            </ul>
          </nav>

          <div className="filter-area">
            <div
              id="filter_drawer"
              className={`san-drawer right${isDrawerOpen ? " open" : ""}`}
              data-reference="filter_drawer"
            >
              <form
                action=""
                className="filter_page_form"
                method="post"
                acceptCharset="utf-8"
              >
                <div className="drawer-card">
                  <div className="drawer-header d-flex align-items-center justify-content-between">
                    <h4>Search Criteria</h4>
                    <button type="button" className="drawer-close"></button>
                  </div>
                  <div className="drawer-body">
                    {/* new search criteria starts here */}
                    <div className="form-group">
                      <label className="form-label">
                        <span>From</span>
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setFromDate(e.target.value)}
                        placeholder="From Date"
                        required
                        value={from_date}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        <span>To</span>
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="date"
                        onChange={(e) => setToDate(e.target.value)}
                        className="form-control"
                        placeholder="To Date"
                        required
                        value={to_date}
                      />
                    </div>

                    <div className="form-group">
                      {folderListData.map((i, j) => {
                        optionsFolderList.push({
                          value: i.folder_id,
                          label: i.folder_name,
                        });
                      })}
                      <label className="form-label">Folders</label>
                      <span className="mandatory">*</span>
                      <select
                        className="form-control"
                        style={{ borderRadius: "100px" }}
                        onChange={(e) => [
                          setFolderID(e.target.value),
                          getFoldersTags(e.target.value),
                          getFoldersApplications(e?.target?.value),
                          setFolderName(
                            e.nativeEvent.target[
                              e.nativeEvent.target.selectedIndex
                            ].text
                          ),
                          setAppListData([]),
                        ]}
                      >
                        <option value="">Select</option>
                        {folderListData.map((i, j) => {
                          return (
                            <option value={i.folder_id}>{i.folder_name}</option>
                          );
                        })}
                      </select>
                      {/* <Select
                                                options={optionsFolderList}
                                                placeholder='Folders'
                                                isSearchable={true}
                                                // value={'appName'}
                                                onChange={handleFolderChange}
                                            /> */}
                    </div>
                    {/* 

                                        <div className="form-group">
                                            <label className="form-label">Applications</label>
                                            <select className="form-control" style={{ borderRadius: '100px' }} onChange={(e) => [setAppID(e.target.value), setAppName(e.target.value)]}>
                                                <option value=''>Select</option>
                                                {appListData.map((i, j) => {
                                                    return (
                                                        <option value={i.application_id}>{i.application_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div> */}

                    <div className="form-group">
                      <label className="form-label">Tags</label>
                      <Select
                            options={tagListData}
                            placeholder="Select Tag"
                            isSearchable={true}
                            isMulti
                            className=""
                            value={ valueOfTags !== '' ? tagListData?.find((option, index) => option.value === valueOfTags) : ''}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options

                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                              Option,
                              ValueContainer: ValueContainerForTag,
                            }}
                            // value={tagValue}
                            onChange={(e, event) => [handleTagChange(e,event),setValueOfTags(e?.value),setApplicationId2(e.value),
                              setTagName(e.value),]}
                          />
                    </div>


                    <div className="form-group">
                      <label className="form-label">Application</label>
                      <Select
                            options={applicationListData}
                            placeholder="Select Application"
                            isSearchable={true}
                            isMulti
                            className=""
                            value={ appValue !== '' ? applicationListData?.find((option, index) => option.value === appValue) : ''}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options

                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                              Option,
                              ValueContainer: ValueContainerForApplication,
                            }}
                            // value={applicationValue}
                            onChange={(e, event) => [handleApplicationChange(e, event),setAppValue(e?.value)]}
                          />
                    </div>

                    {/* <div className="form-group">
                                            <label className="form-label"><span>LOB</span></label>
                                            <MultiSelection isFilter={false} options={Object.values(lobData)} customSelectionName="lob" selectedLobDataFunc={selectedLobDataFunc} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>Compaign</span></label>
                                            <MultiSelection isFilter={false} options={Object.values(compaignData)} customSelectionName="camp" selectedCampDataFunc={selectedCampDataFunc} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>Vendor</span></label>
                                            <MultiSelection isFilter={false} options={Object.values(vendorData)} customSelectionName="vendor" selectedVendorDataFunc={selectedVendorDataFunc} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>Location</span></label>
                                            <MultiSelection isFilter={false} options={Object.values(locData)} customSelectionName="loc" selectedLocDataFunc={selectedLocDataFunc} />
                                        </div> */}
                    
                    <div className=" form-group">
                      <label>
                        <span>Campaign</span>
                      </label>
                      {/* <MultiSelection options={c2Data} customSelectionName={hierarchyData.c2} isFilter={true} isFormFilter={false} value={selectedValue} handleC3Change={(e) => handleC3Change(e, "campaignFunc")} /> */}

                      <Select
                        options={optionsC2}
                        value={
                          selectedCampData !== ""
                            ? optionsC2.find(
                                (option) => option.value === selectedCampData
                              )
                            : ""
                        }
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "100px", // Adjust the border-radius value as per your needs
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        }}
                        components={{
                          Option,
                          ValueContainer: ValueContainer2,
                        }}
                        isMulti
                        placeholder="Select"
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        allowSelectAll={true}
                        //value={formData}
                        onChange={(e) => [
                          setSelectedCampData(e?.map((i) => i?.value)),
                          setSelectedVendorData(""),
                          setSelectedLocData(""),
                        ]}
                      />
                    </div>
                    {/* <div className=" form-group">
                      <label>
                        <span>LOB</span>
                      </label>
                      <Select
                        options={optionsC1}
                        value={
                          selectedLobData !== ""
                            ? optionsC1.find(
                                (option, index) =>
                                  option.value === selectedLobData
                              )
                            : ""
                        }
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "100px", // Adjust the border-radius value as per your needs
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        }}
                        components={{
                          Option,
                          ValueContainer: ValueContainer,
                        }}
                        isMulti
                        placeholder="Select Form"
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        allowSelectAll={true}
                        //value={formData}
                        onChange={(e) => [
                          setSelectedLobData(e?.map((i) => i?.value)),
                          setSelectedCampData(""),
                          setSelectedVendorData(""),
                          setSelectedLocData(""),
                        ]}
                      />
                    </div> */}
                    {/* <div className="form-group">
                      <label>
                        <span>Vendor</span>
                      </label>
                      <Select
                        options={optionsC3}
                        value={
                          selectedVendorData !== ""
                            ? optionsC3.find(
                                (option, index) =>
                                  option.value === selectedVendorData
                              )
                            : ""
                        }
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "100px", // Adjust the border-radius value as per your needs
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        }}
                        components={{
                          Option,
                          ValueContainer: ValueContainer3,
                        }}
                        isMulti
                        placeholder="Select Form"
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        allowSelectAll={true}
                        //value={formData}
                        onChange={(e) => [
                          setSelectedVendorData(e?.map((i) => i?.value)),
                          setSelectedLocData(""),
                        ]}
                      />
                    </div> */}
                    {/* <div className=" form-group">
                      <label>
                        <span>Location</span>
                      </label>
                      <Select
                        options={optionsC4}
                        value={
                          selectedLocData !== ""
                            ? optionsC4.find(
                                (option, index) =>
                                  option.value === selectedLocData
                              )
                            : ""
                        }
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "100px", // Adjust the border-radius value as per your needs
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        }}
                        components={{
                          Option,
                          ValueContainer: ValueContainer4,
                        }}
                        isMulti
                        placeholder="Select Form"
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        allowSelectAll={true}
                        //value={formData}
                        onChange={(e) =>
                          setSelectedLocData(e?.map((i) => i?.value))
                        }
                      />
                    </div> */}
                    {/* {console.log("lobbVALUE2",selectedLobData)}
                                        {console.log("lobbVALUE3",selectedCampData)}
                                        {console.log("lobbVALUE4",selectedVendorData)}
                                        {console.log("lobbVALUE5",selectedLocData)} */}
                    <div className="form-group">
                      <label className="form-label">
                        <span>Call Id</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Call Id"
                        onInput={(e) => setCallID(e.target.value)}
                        value={call_id}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        <span>Agent Id</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Agent Id"
                        value={agent_id}
                        onInput={(e) => setAgentId(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        <span>Terms</span>
                      </label>
                      <div className="d-flex align-items-center">
                        <select
                          className="form-control "
                          name="terms_text"
                          onChange={(e) => [setTermCreator(e.target.value)]}
                        >
                          <option selected={true} value="Speaker">
                            Speaker
                          </option>
                          <option value="Agent">Agent</option>
                          <option value="Client">Client</option>
                          {/* <option value='File'>File</option>
                                                    <option value='Agent ID'>Agent ID</option>
                                                    <option value='Transcript ID'>Transcript ID</option>
                                                    <option value='Request ID'>Request ID</option>
                                                    <option value='Tag'>Tag</option> */}
                        </select>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Term"
                          name="terms_phrase"
                          value={termWord}
                          onChange={(e) => setTermWord(e.target.value)}
                        />
                        <button
                          type="button"
                          disabled={termWord == ""}
                          onClick={setTermSearchCriteria}
                          className="btn btn-outline-primary btn-sm ml-2"
                        >
                          <span>
                            <i className="las la-plus la-2x"></i>
                          </span>
                        </button>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-row">
                        <div className="col-md-6">
                          <label className="form-label">
                            <span>Gender</span>
                          </label>
                          <select
                            className="form-control "
                            onChange={(e) => setGenderOF(e.target.value)}
                          >
                            <option selected={true} value="all_gender">
                              All
                            </option>
                            <option value="client_gender">Client</option>
                            <option value="agent_gender">Agent</option>
                            <option value="speaker_gender">Agent</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            <span></span>
                          </label>
                          <select
                            className="form-control "
                            onChange={(e) => setGenderType(e.target.value)}
                          >
                            <option value="all" selected={true}>
                              All
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-row">
                        <div className="col-md-6">
                          <label className="form-label">
                            <span>Emotions</span>
                          </label>
                          <select
                            className="form-control "
                            onChange={(e) => setEmotionOF(e.target.value)}
                          >
                            <option selected={true} value="all_emotion">
                              Overall
                            </option>
                            <option value="client_emotion">Client</option>
                            <option value="agent_emotion">Agent</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            <span></span>
                          </label>
                          <select
                            className="form-control "
                            onChange={(e) => setEmotionVal(e.target.value)}
                          >
                            <option selected={true} value="All">
                              All
                            </option>
                            <option value="Positive">Positive</option>
                            <option value="Negative">Negative</option>
                            <option value="Improving">Improving</option>
                            <option value="Worsening">Worsening</option>
                            <option value="Neutral">Neutral</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group" style={{ margin: "30px 0" }}>
                      <label className="form-label">
                        <span>Silence Time</span>
                      </label>
                      <MultiRangeSlider
                        rangeLabel={"%"}
                        min={0}
                        max={100}
                        onChange={({ min, max }) =>
                          onChangeSlienceTime(min, max)
                        }
                      />
                      {/* <HorizontalCustomLabels setSilenceTimeValue={setSilenceTimeValue} typeOfSlider="silence" /> */}
                      {/* <input type="text" className="form-control" placeholder="Call Id" required /> */}
                    </div>
                    <div className="form-group" style={{ margin: "30px 0" }}>
                      <label className="form-label">
                        <span>Duration</span>
                      </label>
                      <MultiRangeSlider
                        style={{ height: "15vh" }}
                        min={0}
                        type="time"
                        maxButton={onMaxClick}
                        max={1800000}
                        setMaxFlag={setMaxFlag}
                        maxFlag={maxFlag}
                        onChange={({ min, max }) => onChangeDuration(min, max)}
                      />
                      {/* <HorizontalCustomLabels setDurationValue={setDurationValue} typeOfSlider="duration" /> */}
                      {/* <input type="text" className="form-control custom-startdatepicker" placeholder="From Date" required /> */}
                    </div>
                    {/* new search criteria ends here */}

                    {/* <div className="form-group">
                                            <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control custom-enddatepicker" placeholder="To Date" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>Agent Id</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control" placeholder="Agent Id" required />
                                        </div> */}
                  </div>
                  <div className="drawer-footer d-flex align-items-center justify-content-between">
                    {/* <button type="button" id="filter_sbmtBtn" className="btn btn-secondary alpha">
                                            <span>Reset</span>
                                        </button> */}
                    <button
                onClick={handleSearch}
                type="button"
                className={`btn btn-primary beta add_attributes ${searchLoader ? 'loading' : ''}`}
              >
                <span>Submit</span>
                <span className="loader">
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </span>
              </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="ip-section">
            <div className="container-fluid">
              <div className="ip-form">
                <div className="form-contents">
                  <div className="d-flex flex-wrap align-items-center justify-content-between pb-4">
                    <h4 className="form-title"></h4>
                    <div className="d-flex align-items-center">
                      <div className="ip-header-group d-inline-flex align-items-center">
                        <div className="mr-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-md filter-btn drawer-open"
                            onClick={() => setIsDrawerOpen(true)}
                            data-target="filter_drawer"
                          >
                            Search Criteria
                          </button>
                        </div>
                        <button
                          onClick={handleSearch}
                          className={`btn btn-md filter-btn drawer-open ${
                            searchLoader ? "btn-primary" : "btn-outline-primary"
                          }`}
                          id="quick_search"
                        >
                          Search
                          {searchLoader && (
                            <div className="">
                              <div
                                className="spinner-border text-dark"
                                role="status"
                              ></div>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="ip-ats accordions">
                    <div className="ats-body">
                      {/* basic search, terms, date/gender/emotion dropdown starts here */}
                      {/* <div className="ats-contents mb-3" id="cat1">
                                                <button type="button" className="ats-link" data-target="#ats_accordion_01"><span className="ats-link-text">Basic Search</span></button>
                                                <div id="ats_accordion_01" className="ats-accordion-contents">
                                                    <div className="ftab-section-body">
                                                        <div className="row">

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Call Id</span></label>
                                                                    <input type="text" className="form-control" placeholder="Call Id" onBlur={(e) => setCallId(e.target.value)} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>From</span><span className="mandatory">*</span></label>
                                                                    <input type="date" className="form-control" onChange={(e) => setFromDate(e.target.value)} placeholder="From Date" required />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                                                    <input type="date" onChange={(e) => setToDate(e.target.value)} className="form-control" placeholder="To Date" required />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Agent Id</span></label>
                                                                    <input type="text" className="form-control" placeholder="Agent Id" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>LOB</span><span className="mandatory">*</span></label>
                                                                    <MultiSelection isFilter={false} options={Object.values(lobData)} customSelectionName="lob" selectedLobDataFunc={selectedLobDataFunc} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Compaign</span><span className="mandatory">*</span></label>
                                                                    <MultiSelection isFilter={false} options={Object.values(compaignData)} customSelectionName="camp" selectedCampDataFunc={selectedCampDataFunc} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Vendor</span><span className="mandatory">*</span></label>
                                                                    <MultiSelection isFilter={false} options={Object.values(vendorData)} customSelectionName="vendor" selectedVendorDataFunc={selectedVendorDataFunc} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Location</span><span className="mandatory">*</span></label>
                                                                    <MultiSelection isFilter={false} options={Object.values(locData)} customSelectionName="loc" selectedLocDataFunc={selectedLocDataFunc} />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ats-contents mb-3" id="cat2">
                                                <button type="button" className="ats-link" data-target="#ats_accordion_02"><span className="ats-link-text">Terms</span></button>
                                                <div id="ats_accordion_02" className="ats-accordion-contents">
                                                    <div className="ftab-section-body">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Term</span><span className="mandatory">*</span></label>
                                                                    <select className="form-control " name="terms_text" onChange={(e) => setTermCreator(e.target.value)}>
                                                                        <option>--Select--</option>
                                                                        <option value='Speaker'>Speaker</option>
                                                                        <option value='Agent'>Agent</option>
                                                                        <option value='Client'>Client</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span></span></label>
                                                                    <input type="text" className="form-control" placeholder="Term" name="terms_phrase" onInput={(e) => setTermWord(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ats-contents mb-3" id="cat3">
                                                <button type="button" className="ats-link" data-target="#ats_accordion_03">
                                                    <span className="ats-link-text">Date/Gender/Emotion</span></button>
                                                <div id="ats_accordion_03" className="ats-accordion-contents">
                                                    <div className="ftab-section-body">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Gender</span><span className="mandatory">*</span></label>
                                                                    <select className="form-control " onChange={(e) => setGenderOF(e.target.value)}>
                                                                        <option>--Select--</option>
                                                                        <option value='all_gender'>All</option>
                                                                        <option value='client_gender'>Client</option>
                                                                        <option value='agent_gender'>Agent</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span></span></label>
                                                                    <select className="form-control " onChange={(e) => setGenderType(e.target.value)}>
                                                                        <option>--Select--</option>
                                                                        <option value='all'>All</option>
                                                                        <option value='male'>Male</option>
                                                                        <option value='female'>Female</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span>Emotions</span><span className="mandatory">*</span></label>
                                                                    <select className="form-control " onChange={(e) => setEmotionOF(e.target.value)}>
                                                                        <option>--Select--</option>
                                                                        <option value="all_emotion">Overall</option>
                                                                        <option value="client_emotion">Client</option>
                                                                        <option value="agent_emotion">Agent</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className="form-label"><span></span></label>
                                                                    <select className="form-control " onChange={(e) => setEmotionVal(e.target.value)}>
                                                                        <option>--Select--</option>
                                                                        <option value='Positive'>Positive</option>
                                                                        <option value='Negative'>Negative</option>
                                                                        <option value='Improving'>Improving</option>
                                                                        <option value='Neutral'>Neutral</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                      {/* basic search, terms, date/gender/emotion dropdown ends here */}
                      <div
                        className="ats-contents mb-3"
                        id="cat4"
                        style={{ position: "relative" }}
                      >
                        <button
                          type="button"
                          className="ats-link active"
                          onClick={() =>
                            showBookMark === "d-none"
                              ? setShowBookMark("d-block")
                              : setShowBookMark("d-none")
                          }
                          data-target="#ats_accordion_04"
                        >
                          <span className="ats-link-text">Bookmarks</span>
                        </button>
                        <div
                          id="ats_accordion_04"
                          className={`ats-accordion-contents ${
                            allBookMarksData.length > 0 && showBookMark
                          }`}
                          style={{ display: "block" }}
                        >
                          <div className="ftab-section-body">
                            {allBookMarksData.map((i, j) => {
                              return (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    getBookmarkTags(
                                      i.bookmark_id,
                                      "ats_accordion_05",
                                      `bookmark_id_${j}`
                                    )
                                  }
                                  key={j}
                                  id={`bookmark_id_${j}`}
                                  className="call-tags data-chip"
                                  data-id={i.bookmark_id}
                                >
                                  {i.bookmark_name}
                                  <button
                                    onClick={() =>
                                      removeBookmarks(i.bookmark_id)
                                    }
                                    type="button"
                                    className="chip-del"
                                  >
                                    <i className="las la-times"></i>
                                  </button>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div
                        className="ats-contents accordian-switch-content mb-3"
                        id="cat5"
                      >
                        <a
                          onClick={addTermValue}
                          href="#add-bookmark"
                          className="add-bookmark ml-5"
                          data-toggle="modal"
                          style={{ marginLeft: "25px" }}
                        >
                          Add Bookmark
                        </a>

                        <div className="accordian-switch ml-5">
                          <label
                            className="switch-text mt-1 mr-3"
                            style={{ marginLeft: "17px" }}
                          >
                            OR
                          </label>
                          <Switch
                            width={35}
                            handleDiameter={15}
                            onChange={(e) => [
                              setSwitchBtn(!switchBtn),
                              setTagsOrAndValue(e),
                            ]}
                            checkedIcon=""
                            uncheckedIcon=""
                            height={20}
                            checked={switchBtn}
                          />
                          <label className="switch-text mt-1 ml-2">AND</label>
                        </div>

                        <button
                          type="button"
                          className="ats-link active"
                          data-target="#ats_accordion_05"
                          onClick={() =>
                            showKeyWord === "d-none"
                              ? setShowKeyword("d-block")
                              : setShowKeyword("d-none")
                          }
                        >
                          <span
                            className="ats-link-text"
                            style={{ fontSize: "14px" }}
                          >
                            Keyword Search
                          </span>
                        </button>
                        <div
                          id="ats_accordion_05"
                          className={`ats-accordion-contents ${
                            allBookTagsData.length > 0 && showKeyWord
                          }`}
                          style={{ display: "block" }}
                        >
                          <div className="ftab-section-body">
                            {allBookTagsData.map((i, j) => {
                              // console.log('allBookTagsData------', i)
                              return (
                                <>
                                  <div className="data-chip">
                                    <span
                                      onClick={() =>
                                        setTagsArrFunc(
                                          i.terms_name,
                                          `tag_id_${j}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                      key={j}
                                      id={`tag_id_${j}`}
                                    >
                                      {i.terms_name}
                                      <button
                                        type="button"
                                        onClick={() =>
                                          removeTermsValue(i.terms_name)
                                        }
                                        className="chip-del"
                                      >
                                        <i className="las la-times"></i>
                                      </button>
                                    </span>
                                  </div>
                                  {/* <div className="data-chip"><span onClick={() => setTagsArrFunc(i.terms_text, i.terms_phrase, `tag_id_${j}`)} style={{ cursor: 'pointer' }} key={j} id={`tag_id_${j}`} >{i.terms_name}
                                                                    <button type="button" onClick={() => removeTermsValue(i.terms_text)} className="chip-del"><i className="las la-times"></i></button>
                                                                    </span></div> */}
                                </>
                              );
                            })}
                            {/* <span className="call-tags">Tag1</span>
                                                        <span className="call-tags">Tag2</span>
                                                        <span className="call-tags">Tag3</span>
                                                        <span className="call-tags">Tag4</span>
                                                        <span className="call-tags">Tag5</span>
                                                        <span className="call-tags">Tag6</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ip-section mt-4">
            <div className="container-fluid">
              <div className="ip-table p-0 data_list_div">
                {/* <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="table-title">Call Search</h4>
                                </div> */}

                {/* <div className="table-contents"> */}
                {console.log("BBSSSHHSHSHSHSH",xlsReport)}
                <DataTableView
                  isPending={isLoading}
                  setSearchValue={setSearchValue}
                  columns={columns2?.length>0?columns2:[{Header:"No Data",accessor:"No data"}]}
                  title="Call Search"
                  isPDFReport={true}
                  allData={headersForExport}
                  addRemoveTableValues={addRemoveTableValues}
                  isXLSReport={true}
                  pdfReport={pdfReport}
                  xlsReport={xlsReport}
                  isCSVReport={true}
                  csvReport={csvReport}
                  data={!data ? [] : data}
                  totalRec={!data ? 0 : data?.length}
                  onlyForForms={true}
                  quickSearch={true}
                  assignCalls={false}
                  // checkExport = {checkExport}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* main section */}
      <div
        className="modal fade call-search-modal"
        id="cs_addbook_modal"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Add Bookmark</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group col-md-6">
                  {/* <label>Title</label> */}
                  <input
                    className="form-control"
                    type="text"
                    name=""
                    id=""
                    placeholder="Bookmark Title"
                  />
                </div>
                <div className="form-group col-md-6">
                  {/* <label>Boolean</label> */}
                  <div className="form-switch-area">
                    <div className="ip-switch logic-switch text-left mt-3">
                      <div className="ip-switch-control">
                        <label className="switch-text pl-0" data-val="and">
                          And
                        </label>
                        <button
                          type="button"
                          className="btn-switch"
                          data-value="and"
                        ></button>
                        <label className="switch-text pl-0" data-val="or">
                          Or
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12">
                  <div className="form-row searchterm-area">
                    <div className="form-group col-md-6 col-lg-4">
                      <div className="search-item">
                        <div className="si-content d-flex align-items-center">
                          <span className="si-term">speaker</span>
                          <span className="si-text flex-grow-1 text-center">
                            Aman
                          </span>
                        </div>
                        <button type="button"></button>
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-lg-4">
                      <div className="search-item">
                        <div className="si-content d-flex align-items-center">
                          <span className="si-term">Client</span>
                          <span className="si-text flex-grow-1 text-center">
                            Abhijeet
                          </span>
                        </div>
                        <button type="button"></button>
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-lg-4">
                      <div className="search-item">
                        <div className="si-content d-flex align-items-center">
                          <span className="si-term">Agent</span>
                          <span className="si-text flex-grow-1 text-center">
                            Armaan Malik
                          </span>
                        </div>
                        <button type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12">
                  <div className="form-row">
                    <div className="form-group term-group col-md-6">
                      {/* <label className="d-flex flex-wrap"><span>Term</span></label> */}
                      <div className="form-row group no-gutters">
                        <div className="col-4">
                          <select className="form-control" name="term_cat">
                            <option value="speakers">Speakers</option>
                            <option value="agent">Agent</option>
                            <option value="client">Client</option>
                            <option value="file">File</option>
                            <option value="agent_id">Agent ID</option>
                            <option value="transcript_id">Transcript ID</option>
                            <option value="request_id">Request ID</option>
                            <option value="tag">Tag</option>
                          </select>
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            name="term_text"
                            placeholder="Enter text to search"
                          />
                        </div>
                        <div className="col-2">
                          <button
                            className="btn btn-term-add"
                            type="button"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button type="button" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade call-search-modal"
        id="cs_managebook_modal"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Manage Bookmarks</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table id="" className="table thead-primary dataTable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Bookmark 1</td>
                    <td>
                      <ul className="action-strip primary iconic">
                        <li>
                          <a href="#">
                            <i className="las la-edit new_edit_btn edit_btn"></i>
                          </a>
                          <span className="iconic-tooltip">Edit</span>
                        </li>
                        <li>
                          <a href="#">
                            <i className="las la-trash"></i>
                          </a>
                          <span className="iconic-tooltip">Delete</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Bookmark 2</td>
                    <td>
                      <ul className="action-strip primary iconic">
                        <li>
                          <a href="#">
                            <i className="las la-edit new_edit_btn edit_btn"></i>
                          </a>
                          <span className="iconic-tooltip">Edit</span>
                        </li>
                        <li>
                          <a href="#">
                            <i className="las la-trash"></i>
                          </a>
                          <span className="iconic-tooltip">Delete</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Bookmark 3</td>
                    <td>
                      <ul className="action-strip primary iconic">
                        <li>
                          <a href="#">
                            <i className="las la-edit new_edit_btn edit_btn"></i>
                          </a>
                          <span className="iconic-tooltip">Edit</span>
                        </li>
                        <li>
                          <a href="#">
                            <i className="las la-trash"></i>
                          </a>
                          <span className="iconic-tooltip">Delete</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Bookmark 4</td>
                    <td>
                      <ul className="action-strip primary iconic">
                        <li>
                          <a href="#">
                            <i className="las la-edit new_edit_btn edit_btn"></i>
                          </a>
                          <span className="iconic-tooltip">Edit</span>
                        </li>
                        <li>
                          <a href="#">
                            <i className="las la-trash"></i>
                          </a>
                          <span className="iconic-tooltip">Delete</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Bookmark Modal --> */}
      <div
        className="modal fade modal-large show"
        id="add-bookmark"
        tabindex="-1"
        aria-labelledby="boxModelLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header ip-gradient">
              <h5 className="modal-title">Add Bookmark</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body add-bookmark">
              <div className="form-group">
                <label className="form-label">
                  <span>Name</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bookmark Name"
                  required
                  onInput={(e) => setBookMarkValue(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  <span>Boolean</span>
                </label>
                <div className="ip-switch text-left pt-2">
                  <div className="ip-switch-control">
                    <label className="switch-text mr-2">OR</label>
                    <Switch
                      width={35}
                      handleDiameter={15}
                      onChange={(e) => [
                        setSwitchBtn(!switchBtn),
                        setBookmarkBooleanValue(e),
                      ]}
                      checkedIcon=""
                      uncheckedIcon=""
                      height={20}
                      checked={switchBtn}
                    />
                    <label className="switch-text">AND</label>
                  </div>

                  {/* <div className="ip-switch-control">
                                        <label className="switch-text">And</label>
                                        <button type="button" className="btn-switch attribute-switch" data-ref="opportunity"></button>
                                        <label className="switch-text pl-0">Or</label>
                                    </div> */}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">
                  <span>Search Terms</span>
                </label>
                <div className="chips-body" data-ref="include-collection">
                  {searchTermValue.map((i, j) => {
                    return (
                      <div className="data-chip">
                        <span>{i}</span>
                        <button type="button" className="chip-del">
                          <i className="las la-times"></i>
                        </button>
                      </div>
                    );
                  })}
                  {/* <div className="data-chip"><span>Client :Bye</span>
                                        <button type="button" className="chip-del"><i className="las la-times"></i></button>
                                    </div>
                                    <div className="data-chip"><span>Client :Bye</span>
                                        <button type="button" className="chip-del"><i className="las la-times"></i></button>
                                    </div> */}
                </div>
              </div>
              <hr />
              <div className="form-group">
                <label className="form-label">
                  <span>Term</span>
                </label>
                <div className="d-flex align-items-center">
                  <select
                    className="form-control "
                    onChange={(e) => setTerm(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Speaker">Speaker</option>
                    <option value="Client">Client</option>
                    <option value="Agent">Agent</option>
                  </select>
                  <input
                    type="text"
                    onInput={(e) => setTermText(e.target.value)}
                    className="form-control ml-2"
                    placeholder="Enter Text to Search"
                  />
                  <button
                    onClick={setSearchTerm}
                    className="btn btn-outline-primary btn-sm ml-2"
                  >
                    <span>
                      <i className="las la-plus la-2x"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div className="modal-footer justify-content-center pt-0 pb-0">
                <button type="button" className="btn btn-secondary">
                  <span>Cancel</span>
                </button>
                <button
                  onClick={handleAddBookmark}
                  type="button"
                  className="btn btn-primary"
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End --> */}
      {/* <Footer /> */}
    </div>
  );
};

export default SSCallSearch;
